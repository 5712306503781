<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :data="tree" :expand-on-click-node="false" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]" :default-checked-keys="[1]"
				 :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.regionname}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form :inline="true">
						<el-form-item label="小区名：">
							<el-input class="shuru" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item label="类型：">
							<el-select v-model="leixing" filterable>
								<el-option label="全部" value="">
								</el-option>
								<el-option label="农村组" value="1">
								</el-option>
								<el-option label="小区" value="2">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button v-if="newbutton" type="primary" @click="add()"><i class="el-icon-plus"></i> 新增</el-button>
						</el-form-item>
					</el-form>
					
					
				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column prop="name" label="社区" align="center">
						</el-table-column>
						<el-table-column prop="regionname" label="小区" align="center">
						</el-table-column>
						<el-table-column prop="wuyename" label="物业" align="center">
						</el-table-column>
						<el-table-column prop="typename" label="类型" align="center">
						</el-table-column>
						<el-table-column label="操作" width="200" align="center">
							<template slot-scope="scope">
								<el-button @click="xiugai(scope.row)" type="primary">修改</el-button>
								<el-button @click="shanchu(scope.row.id)" type="primary">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper"
						 :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>
				<el-dialog :title="type == 'add'?'新增小区信息':'修改小区信息'" width='30%' :visible.sync="dialogTableVisible" :before-close="handleClose"
				 :close-on-click-modal="false">
					<div class="xinzeng">
						<el-form ref="form" :model="form" label-width="130px">
							<el-form-item label="小区地址">
								<el-input v-model="form.address"></el-input>
							</el-form-item>
							<el-form-item label="小区名称">
								<el-input v-model="form.name"></el-input>
							</el-form-item>
							<el-form-item label="详细地址1">
								<el-input v-model="form.address1"></el-input>
							</el-form-item>
							<!-- <el-form-item label="物业公司">
								<el-input v-model="form.wuyename"></el-input>
							</el-form-item>
							<el-form-item label="物业电话">
								<el-input v-model="form.phone"></el-input>
							</el-form-item> -->
							<el-form-item label="类型">
								<el-select v-model="form.type" filterable style="width: 100%;">
									<el-option label="农村组" value="1">
									</el-option>
									<el-option label="小区" value="2">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注">
								<el-input v-model="form.address2"></el-input>
							</el-form-item>
							<el-form-item label="排序">
								<el-input-number v-model="form.sort" controls-position="right" :min="1"></el-input-number>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit">{{type == 'add'?'新增':'修改'}}</el-button>
								<el-button @click="close">取消</el-button>
							</el-form-item>
						</el-form>
					</div>

				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getxiaoqulist,
		addxiaoqu,
		xiaoqulist,
		editxiaoqu,
		deletexiaoqu
	} from '@/api/managexiaoqu'
	export default {
		data() {
			return {
				leixing:'',
				type:'add',
				time: new Date(),
				treeId: "",
				searchName: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				form: {
					address: '',
					name: '',
					address1: '',
					address2: '',
					wuyename: '',
					phone: '',
					id:'',
					regionlevel:'',
					type:'',
					sort: 1
				},
				defaultProps: {
					children: 'regionlist',
					label: 'regionname'
				},
				treeSelectId: "",
				tree: [],
				newbutton: false,
				levelcode: '',
				regioncode: '',
				loading1: true,
				loading2: true
			}
		},
		watch: {

		},
		mounted() {
			this.getTree();
			this.getList();
		},
		methods: {
			// nodeClick() {
			// 	let node = this.$refs.tree.getCurrentNode()
			// 	this.$emit("treeClick", node.name, node.id)
			// },
			shanchu(a) {
				var that = this;
				that.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deletexiaoqu({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						id: a
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.currentPage = 1;
							that.getTree();
							that.getList();
						} else {
							that.$message({
								showClose: true,
								type: 'error',
								message: res.description
							});
						}
					})
			
				}).catch(() => {
			
				});
			},
			add(){
				this.type = 'add';
				this.dialogTableVisible = true;
			},
			xiugai(a){
				let that = this;
				that.form = {
					address: a.regionarea,
					name: a.regionname,
					address1: a.regionareabak,
					address2: a.regionareabak2,
					wuyename: a.wuyename,
					phone: a.wuyephone,
					id:a.id,
					sort: a.sort,
					regionlevel:a.region_level,
					type:a.type?a.type + '':''
				};
				that.type = 'edit';
				that.dialogTableVisible = true;
			},
			getTree() {
				this.loading1 = true;
				getxiaoqulist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
					type: 0
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
						// console.log(this.tree);
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].regionname = this.tree[a].name + '(' + this.tree[a].regionnum + ')';
							this.tree[a].ji = 1;
							this.tree[a].num = a;
							for (var i = 0; i < this.tree[a].regionlist.length; i++) {
								if(this.tree[a].regionlist[i].type == 1){
									this.tree[a].regionlist[i].regionname += '(农村组)';
								}else if(this.tree[a].regionlist[i].type == 2){
									this.tree[a].regionlist[i].regionname += '(小区)';
								}
								this.tree[a].regionlist[i].name = this.tree[a].name;
								this.tree[a].regionlist[i].ji = 2;
								this.tree[a].regionlist[i].num = i;
								this.tree[a].regionlist[i].prevnum = a;
							}
						}
					
						// this.tableData = this.tree[0].regionlist;
						// this.$nextTick(() => {
						// 	this.$refs.tree.setCurrentKey(3);
						// })
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				let that = this;
				that.qingkong();
				done();
			},
			qingkong(){
				let that = this;
				that.form = {
					address: '',
					name: '',
					address1: '',
					address2: '',
					sort: 1,
					wuyename: '',
					phone: '',
					id:'',
					regionlevel:'',
					type:''
				}
			},

			onSubmit() {
				var that = this;
				if (that.form.address == '') {
					that.$message.error('请填写小区地址');
					return;
				}
				if (that.form.name == '') {
					that.$message.error('请填写小区名称');
					return;
				}
				if (that.form.type == '') {
					that.$message.error('请选择类型');
					return;
				}
				if(that.type == 'add'){
					addxiaoqu({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionlevel: that.levelcode,
						regionArea: that.form.address,
						regionName: that.form.name,
						regionAreaBak: that.form.address1,
						regionAreaBak2: that.form.address2,
						sort: that.form.sort,
						wuyename: that.form.wuyename,
						wuyephone: that.form.phone,
						type:that.form.type
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								message: '添加成功',
								type: 'success'
							});
							that.qingkong();
							that.levelcode = '';
							that.regioncode = '';
							that.searchName = '';
							that.getTree();
							that.getList();
							that.dialogTableVisible = false;
						} else {
							this.$message.error(res.description);
						}
					})
				}else{
					editxiaoqu({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionlevel: that.form.regionlevel,
						regionArea: that.form.address,
						regionName: that.form.name,
						regionAreaBak: that.form.address1,
						regionAreaBak2: that.form.address2,
						sort: that.form.sort,
						wuyename: that.form.wuyename,
						wuyephone: that.form.phone,
						type:that.form.type,
						id:that.form.id
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								message: '修改成功',
								type: 'success'
							});
							that.qingkong();
							that.levelcode = '';
							that.regioncode = '';
							that.searchName = '';
							that.getTree();
							that.getList();
							that.dialogTableVisible = false;
						} else {
							this.$message.error(res.description);
						}
					})
				}
				
			},
			close() {
				var that = this;
				that.dialogTableVisible = false;
				that.form.name = '';
				that.form.wuyename = '';
				that.form.address = '';
				that.form.phone = '';
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				console.log(data);
				var that = this;
				// that.tableData = [];
				that.currentPage = 1;
				that.searchName = '';
				that.levelcode = '';
				that.regioncode = '';
				if (data.ji == 1) {
					that.newbutton = true;
					that.levelcode = data.levelcode;
				} else {
					that.newbutton = false;
					that.levelcode = data.region_level;
					that.regioncode = data.regioncode;
				}
				that.getList();
			},
			getList() {
				var that = this;
				// console.log(that.currentPage);
				that.loading2 = true;
				xiaoqulist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					regioncode: that.regioncode,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					seachname: that.searchName,
					type: '',
					status:that.leixing
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			chaxun() {
				this.currentPage = 1;
				this.getList();
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.xinzeng {
		width: calc(100% - 20px);
	}

	.block{
		margin-top: 20px;
	}
</style>
