import request from '@/utils/request'

export function getxiaoqulist(data) {
	return request({
		url: '/region/getxiaoqulist',
		method: 'get',
		params: data
	})
}
export function addxiaoqu(data) {
	return request({
		url: '/region/addxiaoqu',
		method: 'post',
		params: data
	})
}
export function editxiaoqu(data) {
	return request({
		url: '/region/editxiaoqu',
		method: 'get',
		params: data
	})
}
export function deletexiaoqu(data) {
	return request({
		url: '/region/deletexiaoqu',
		method: 'get',
		params: data
	})
}
export function xiaoqulist(data) {
	return request({
		url: '/region/xiaoqulist',
		method: 'get',
		params: data
	})
}
export function updateBuidMapInfo(data) {
	return request({
		url: '/region/updateBuidMapInfo',
		method: 'post',
		params: data
	})
}
export function addmulbuild(data) {
	return request({
		url: '/region/addmulbuild',
		method: 'post',
		params: data
	})
}
export function deletebuild(data) {
	return request({
		url: '/region/deletebuild',
		method: 'post',
		params: data
	})
}
export function getbuildlist(data) {
	return request({
		url: '/region/getbuildlist',
		method: 'post',
		params: data
	})
}
export function deletehouselist(data) {
	return request({
		url: '/region/deletehouselist',
		method: 'post',
		params: data
	})
}
export function addmulbuildhouse(data) {
	return request({
		url: '/region/addmulbuildhouse',
		method: 'post',
		params: data
	})
}
export function gethouselist(data) {
	return request({
		url: '/region/gethouselist',
		method: 'post',
		params: data
	})
}